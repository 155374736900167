<template>
  <div class="t-main --dark">
    <div class="d-flex justify-space-between align-center py-5 mb-3">
      <h3 class="page-title-list" v-if="bookingTicketTypeList.intendedClient">
        {{ $t('corporateTicket.corporateTicketBookingCreation') }}
        {{ bookingTicketTypeList.intendedClient.name }}
        {{ bookingTicketTypeList.intendedClient.id }}
      </h3>
      <div class="d-flex">
        <v-btn class="t-btn--red-dark" @click="$windowClose">
          <v-icon>mdi-close</v-icon>
          {{ $t('buttons.close') }}
        </v-btn>
        <v-btn class="t-btn--2nd ml-4" @click="actionCreate()">{{
          $t('buttons.bookingRoom')
        }}</v-btn>
      </div>
    </div>

    <v-form ref="form">
      <v-row class="bg--white py-6 px-8">
        <!-- =============== Read only =============== -->
        <v-col cols="12" md="5">
          <v-card class="py-3 px-4 bg--secondary">
            <v-btn
              class="t-btn--2nd mb-8"
              @click="isShowFrameSelection = true"
              >{{ $t('buttons.importFromCalendar') }}</v-btn
            >

            <label>{{ $t('commons.usageContract') }}</label>
            法人チケット

            <div class="d-flex justify-space-between mt-8">
              <div class="pr-2 w-50">
                <label>{{ $t('corporateTicket.selectAFacility') }}</label>
                <v-select
                  v-model="facilityId"
                  @change="selectFac($event)"
                  :items="arrListRoomType"
                  item-value="facilityId"
                  item-text="facilityName"
                >
                </v-select>
              </div>
              <div class="pl-2 w-50">
                <label>{{ $t('customerMember.list.roomType') }}</label>
                <v-select
                  @change="selectRoomType($event)"
                  v-model="roomTypeId"
                  :items="roomTypeListSelect"
                  item-value="roomTypeId"
                  item-text="roomTypeName"
                >
                </v-select>
              </div>
            </div>

            <div class="mt-8 d-flex align-end">
              <v-icon color="black">mdi-calendar-month</v-icon>
              <div class="ml-2">
                <label class="d-block mb-1">{{ $t('commons.checkIn') }}</label>
                <v-menu transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on }">
                    <div class="custom-btn-picker">
                      <v-btn outlined v-on="on" small class="bg-white--btn">{{
                        formatDate(dateCheckIn)
                      }}</v-btn>
                    </div>
                  </template>
                  <v-date-picker
                    @change="changeCheckInDate($event)"
                    :min="bookingTicketTypeList.availableFromDate"
                    :max="bookingTicketTypeList.availableToDate"
                    v-model="dateCheckIn"
                    :first-day-of-week="0"
                    :locale="$i18n.locale"
                    scrollable
                    class="v-date-picker-custom"
                  ></v-date-picker>
                </v-menu>
              </div>
              <label class="mx-5">〜</label>
              <div class="mr-2">
                <label class="d-block mb-1">{{ $t('commons.checkOut') }}</label>
                <div class="text--small">{{ formatDate(dateCheckOut) }}</div>
              </div>
              <span class="text-h6 mb-n1">{{ 1 + $t('commons.nights') }}</span>
            </div>

            <div class="d-flex mt-8">
              <v-row>
                <v-col :cols="2">
                  <label>{{ $t('commons.adult') }}</label>
                  <v-select v-model="numberOfAdults" :items="itemSelect">
                  </v-select>
                </v-col>
                <v-col :cols="2">
                  <label>{{ $t('corporateTicket.childNonSleeping') }}</label>
                  <v-select
                    v-model="numberOfChildrenWithBedShare"
                    :items="itemSelect"
                  >
                  </v-select>
                </v-col>
                <v-col :cols="2">
                  <label>{{ $t('corporateTicket.childCoSleeping') }}</label>
                  <v-select v-model="numberOfChildren" :items="itemSelect">
                  </v-select>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" offset="0" offset-md="1">
          <div class="w-50 mb-8">
            <label>{{ $t('corporateTicket.remark') }}</label>
            <v-text-field
              hide-details
              class="pt-0"
              v-model="bookingBarNote"
            ></v-text-field>
          </div>

          <label>{{ $t('corporateTicket.lodgingRepresentative') }}</label>
          <v-combobox
            hide-details
            @input="getSelectName($event)"
            class="pt-0 mb-8"
            :items="listMembers"
            item-text="name"
            item-value="id"
          ></v-combobox>

          <div class="disabled-box">
            <label>{{
              $t('corporateTicket.lodgingRepresentativeYomigana')
            }}</label>
            <v-text-field
              :rules="[$rules.checkKatakana, $rules.checkLenghInput(255)]"
              v-model="representativeKana"
              class="text-title pt-0"
            >
            </v-text-field>

            <label>{{ $t('commons.phone') }}</label>
            <!-- <v-text-field
                :rules="[$rules.checkNumber, $rules.checkLenghInput(15)]"
                v-model="representativeTel"
                :class="
                        status ? 'disable': ''
                      "
                class="text-title pt-0"
              >
            </v-text-field> -->
            <v-text-field v-model="representativeTel" class="text-title pt-0">
            </v-text-field>

            <label>{{ $t('webPageManage.infoRequestDetail.email') }}</label>
            <v-text-field
              :rules="[$rules.isEmailValid]"
              class="text-title pt-0"
              v-model="representativeEmail"
            >
            </v-text-field>

            <label>{{ $t('corporateTicket.selectACountry') }}</label>
            <v-select
              :items="['日本', 'その他']"
              class="text-title pt-0"
              v-model="representativeCountry"
            >
            </v-select>

            <label>{{ $t('commons.zipCode') }}</label>
            <v-text-field
              :rules="[$rules.postalCodeNoHyphen]"
              v-model="representativePostalCode"
              class="text-title pt-0"
            >
            </v-text-field>

            <label>{{ $t('advertising.policies.address') }}</label>
            <v-text-field
              :rules="[$rules.checkLenghInput(255)]"
              v-model="representativeAddress1"
              class="text-title pt-0"
            >
            </v-text-field>
          </div>
        </v-col>
        <!-- ========================================== -->
      </v-row>
    </v-form>

    <FrameSelectionDialog
      :canClickNext="canClickNext"
      :defaultFacilityId="facilityIdList[0]"
      :fromDateDefault="fromDateDefault"
      :toDateDefault="toDateDefault"
      @getData="getData"
      v-if="isShowFrameSelection"
      :facilityIdList="facilityIdList"
      :roomTypeSelect="roomTypeSelect"
      :isShowFrameSelection.sync="isShowFrameSelection"
      contractName="法人チケット"
    />
  </div>
</template>

<script>
import {
  BOOKING_TICKET_TYPE,
  CLIENT_MEMBER_INFO,
  CREATE_BOOKING_V4,
} from '@/api/graphql/ticketDetail/ticket-detail';
import FrameSelectionDialog from '@/views/customer/response/VMTemporaryContract/FrameSelectionDialog';
import moment from 'moment';
import { handlErrorView, getCopyErrorTextView } from '@/constants/functions';
import { mapMutations } from 'vuex';
import gql from 'graphql-tag';
import { isEmailValid } from '@/utils/validate.js';
import {getISODate} from '@/utils/dateUtil'

export default {
  name: 'TicketBookingCreate',
  data() {
    return {
      fromDateDefault: '',
      toDateDefault: '',
      toDate: '',
      status: false,
      representativeClientId: null,
      representativeSubMemberId: null,
      representativeName: '',
      representativeKana: '',
      representativeTel: '',
      representativeEmail: '',
      representativeAddress1: '',
      representativePostalCode: '',
      representativeCountry: '',
      facilityIdList: [],
      isShowFrameSelection: false,
      statusMember: null,
      listMembers: [],
      facilityId: null,
      roomTypeId: null,
      roomTypeListSelect: [],
      arrListRoomType: [],
      itemSelect: [],
      numberOfAdults: 1,
      numberOfChildrenWithBedShare: 0,
      numberOfChildren: 0,
      bookingTicketTypeList: [],
      facilitySelect: [],
      roomTypeSelect: [],
      arrRoomType: [],
      facilityValue: '箱根強羅',
      roomTypeValue: '禁煙',
      dateCheckIn: new Date().toISOString().substr(0, 10),
      dateCheckOut: new Date().toISOString().substr(0, 10),
      numOfNights: 3,
      numOfAdult: 3,
      numOfChildNonSleeping: 0,
      numOfChildCoSleeping: 0,
      bookingTypeId: null,
      days: [{ inventoryTypeId: 1 }],
      parkings: [],
      bookingBarNote: '',

      remark: '',
      listLodging: [1, 2, 3],
      lodgingSelected: 1,

      lodgingRepresentative: '宿泊代表者ヨミガナ',
      phone: '0000000',
      email: '',
      country: '日本',
      zipCode: '1234567890',
      address: '住所',

      rules: {
        email: [
          (v) => !!v || this.$t('rules.isRequired'),
          (v) => v?.length < 1024 || this.$t('rules.isInvalid'),
          (v) => !v || isEmailValid(v) || this.$t('rules.emailIsInvalid'),
        ],
        zipCode: [
          (v) => !!v || this.$t('rules.isRequired'),
          (v) => this.checkFormatZipCode(v) || this.$t('rules.isInvalid'),
        ],
      },
    };
  },

  components: {
    FrameSelectionDialog,
  },

  mounted() {
    this.getBookingTicketType();
    this.getListItemSelect();
  },

  methods: {
    ...mapMutations([
      'setCopyErrorText',
      'setLoadingOverlayShow',
      'setLoadingOverlayHide',
      'setAlertSuccess',
      'setAlertError',
      'setPermissionUser',
      'setRoleAdminUser',
    ]),
    handlErrorView,
    getCopyErrorTextView,

    canClickNext({ selected }) {
      return selected.length === 1;
    },

    async getData(e) {
      this.days = [
        {
          inventoryTypeId: e.selectedReservationRooms[0].inventoryTypeId,
        },
      ];
      this.facilityId = e.facility.id
      this.roomTypeId = parseInt(e.selectedReservationRooms[0].roomTypeId);
      await this.getBookingTicketType();
      this.dateCheckIn = await e.selectedReservationRooms[0].date;
      await this.changeCheckInDate(this.dateCheckIn);
      this.selectFac(this.facilityId)
    },

    selectFac(event) {
      this.roomTypeListSelect = [];
      for (let i = 0; i < this.arrListRoomType.length; i++) {
        if (event === this.arrListRoomType[i].facilityId) {
          this.roomTypeListSelect.push(this.arrListRoomType[i]);
        }
      }
    },

    checkFormatZipCode(v) {
      if (v.length !== 8) return false;
      else {
        if (!v.includes('-')) return false;
        else {
          const newArr = v.split('-');
          if (
            parseInt(newArr[0]).toString().length === 3 &&
            !isNaN(parseInt(newArr[0])) &&
            parseInt(newArr[1]).toString().length === 4 &&
            !isNaN(parseInt(newArr[1]))
          ) {
            return true;
          } else return false;
        }
      }
    },

    async getListName() {
      this.listMembers = [];
      const variables = {
        id: this.bookingTicketTypeList.intendedClient.id,
      };
      await this.$apollo
        .query({
          query: gql`
            ${CLIENT_MEMBER_INFO}
          `,
          variables: variables,
        })
        .then((data) => {
          const client = {
            id: 999999,
            clientId: data.data.clientMemberInfo.id,
            name: data.data.clientMemberInfo.name,
            nameKana: data.data.clientMemberInfo.nameKana,
            telPreference: data.data.clientMemberInfo.telPreference,
            email: data.data.clientMemberInfo.email,
            address1: '',
            representativePostalCode: '',
            representativeCountry: '',
          };
          this.listMembers = data.data.clientMemberInfo.subMembers;
          this.listMembers.unshift(client);
        })
        .catch(async (error) => {
          this.setCopyErrorText(
            this.getCopyErrorTextView(
              CLIENT_MEMBER_INFO,
              variables,
              error.graphQLErrors,
            ),
          );
          const errorTmp = await handlErrorView(
            error.graphQLErrors,
            this.setPermissionUser,
            this.setRoleAdminUser,
          );
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true });
          }
        });
    },

    selectRoomType(event) {
      this.roomTypeId = event;
    },

    changeCheckInDate(event) {
      const today = new Date(event);
      const nextday = new Date(today);
      nextday.setDate(today.getDate() + 1);
      this.dateCheckOut = new Date(nextday).toISOString().substr(0, 10);
    },

    async actionCreate() {
      if (this.$refs.form.validate()) {
        this.setLoadingOverlayShow();
        const variables = {
          bookingTypeId: 4,
          representativeName: this.representativeName,
          representativeKana: this.representativeKana,
          representativeTel: this.representativeTel,
          representativeEmail: this.representativeEmail || null,
          representativeCountry: this.representativeCountry || null,
          representativePostalCode: this.representativePostalCode || null,
          representativeAddress1: this.representativeAddress1,
          ticketId: this.$route.query.ticketId,
          checkInDate: this.dateCheckIn,
          roomTypeId: this.roomTypeId,
          days: this.days,
          numberOfAdults: this.numberOfAdults,
          numberOfChildren: this.numberOfChildren,
          numberOfChildrenWithBedShare: this.numberOfChildrenWithBedShare,
          parkings: this.parkings,
          bookingBarNote: this.bookingBarNote,
        };
        const finalize = () => this.$apollo
          .mutate({
            mutation: gql`
              ${CREATE_BOOKING_V4}
            `,
            variables: variables,
          })
          .then((data) => {
            this.setLoadingOverlayHide();
            this.setAlertSuccess(this.$t('messages.successfulUpdate'));
            this.$goToRoute.booking(data.data.createBookingV4.id)
            this.closeDialog();
          })
          .catch(async (error) => {
            this.setLoadingOverlayHide();
            this.setCopyErrorText(
              this.getCopyErrorTextView(
                CREATE_BOOKING_V4,
                variables,
                error.graphQLErrors,
              ),
            );
            const errorTmp = await handlErrorView(
              error.graphQLErrors,
              this.setPermissionUser,
              this.setRoleAdminUser,
            );
            if (errorTmp) {
              this.setAlertError(errorTmp, { root: true });
            } else {
              this.setAlertError(this.$t('messages.updateError'));
            }
          });

        if (this.dateCheckIn < getISODate(new Date())) {
          // if checkInDate is in the past, show confirm dialog TO2020-937
          this.$confirm({
            message: '過去の日付で予約を作成します。よろしいですか？',
            ok: finalize,
          })
          this.setLoadingOverlayHide();
        } else {
          await finalize()
        }
      }
    },

    async getBookingTicketType() {
      const variables = {
        id: this.$route.query.id,
      };
      await this.$apollo
        .query({
          query: gql`
            ${BOOKING_TICKET_TYPE}
          `,
          variables: variables,
          fetchPolicy: 'no-cache',
        })
        .then((data) => {
          this.bookingTicketTypeList = data.data.bookingTicketType;
          this.dateCheckIn = this.bookingTicketTypeList.availableFromDate;
          this.fromDateDefault = this.bookingTicketTypeList.availableFromDate;
          this.toDateDefault = this.bookingTicketTypeList.availableToDate;
          this.changeCheckInDate(this.dateCheckIn);
          this.getValueRoomType();
          this.getListName();
          document.title =
            '法人チケット予約作成 ' +
              this.bookingTicketTypeList.intendedClient.name +
              ' ' +
              this.bookingTicketTypeList.intendedClient.id || 'CờRờMờ';

          this.arrListRoomType = [];
          for (
            let i = 0;
            i < data.data.bookingTicketType.roomTypes.length;
            i++
          ) {
            var obj = {
              facilityId:
                data.data.bookingTicketType.roomTypes[i].roomType.facility.id,
              facilityName:
                data.data.bookingTicketType.roomTypes[i].roomType.facility.name,
              roomTypeId: data.data.bookingTicketType.roomTypes[i].roomType.id,
              roomTypeName:
                data.data.bookingTicketType.roomTypes[i].roomType.name,
            };
            this.arrListRoomType.push(obj);
          }
          this.roomTypeListSelect = this.arrListRoomType;
        })
        .catch(async (error) => {
          this.setCopyErrorText(
            this.getCopyErrorTextView(
              BOOKING_TICKET_TYPE,
              variables,
              error.graphQLErrors,
            ),
          );
          const errorTmp = await handlErrorView(
            error.graphQLErrors,
            this.setPermissionUser,
            this.setRoleAdminUser,
          );
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true });
          }
        });
    },

    getListItemSelect() {
      for (let i = 0; i < 10; i++) {
        this.itemSelect.push(i);
      }
    },

    getSelectName(event) {
      if (event.id) {
        if (event.id === 999999) {
          this.status = true;
          this.representativeClientId = this.listMembers[0].clientId;
          this.representativeSubMemberId = null;
          this.representativeName = event.name;
          this.representativeKana = event.nameKana;
          this.representativeTel = event.telPreference;
          this.representativeEmail = event.email;
          this.representativeAddress1 = '';
          this.representativePostalCode = '';
          this.representativeCountry = '';
        } else {
          this.status = true;
          this.representativeCountry = event.locality;
          this.representativePostalCode = event.postalCode;
          this.representativeClientId = null;
          this.representativeSubMemberId = event.id;
          this.representativeName = event.name;
          this.representativeKana = event.nameKana;
          this.representativeTel = event.tel;
          this.representativeEmail = event.email;
          this.representativeAddress1 = `${event?.prefecture || ''} ${
            event?.locality || ''
          } ${event?.address1 || ''} ${event?.address2 || ''}`;
        }
      } else {
        this.status = false;
        this.representativeName = event;
        this.representativeClientId = null;
        this.representativeSubMemberId = null;
        this.representativeKana = '';
        this.representativeTel = '';
        this.representativeEmail = '';
        this.representativeAddress1 = '';
        this.representativePostalCode = '';
        this.representativeCountry = '';
      }
    },

    getValueRoomType() {
      this.facilitySelect = [];
      this.roomTypeSelect = [];
      this.arrRoomType = [];

      for (let i = 0; i < this.bookingTicketTypeList.roomTypes.length; i++) {
        const data = {
          roomTypeId: this.bookingTicketTypeList.roomTypes[i].roomType.id,
          roomTypeName: this.bookingTicketTypeList.roomTypes[i].roomType.name,
          facilityId:
            this.bookingTicketTypeList.roomTypes[i].roomType.facility.id,
          facilityName:
            this.bookingTicketTypeList.roomTypes[i].roomType.facility.name,
        };
        this.facilitySelect.push(
          this.bookingTicketTypeList.roomTypes[i].roomType.facility.id,
        );
        this.roomTypeSelect.push(
          this.bookingTicketTypeList.roomTypes[i].roomType.id,
        );
        this.facilityIdList.push(
          this.bookingTicketTypeList.roomTypes[i].roomType.facility.id,
        );
        this.arrRoomType.push(data);
      }
    },

    formatDate(value) {
      var d = moment(value).format('dddd');
      switch (d) {
        case 'Sunday':
          d = '日';
          break;
        case 'Monday':
          d = '月';
          break;
        case 'Tuesday':
          d = '火';
          break;
        case 'Wednesday':
          d = '水';
          break;
        case 'Thursday':
          d = '木';
          break;
        case 'Friday':
          d = '金';
          break;
        case 'Saturday':
          d = '土';
          break;
      }
      if (moment(value) > moment(this.dateTo)) {
        this.dateTo = value;
      }
      return moment(value).format('yyyy年MM月DD日 (' + d + ')');
    },
  },
};
</script>

<style lang="scss" scoped>
.w-50 {
  width: 50%;
}
label {
  font-size: 10px;
  display: block;
}
.disabled-box label {
  color: var(--text_inactive_aaaaaa);
}
::v-deep {
  .v-text-field.v-input--is-disabled .v-input__slot::before {
    border-image: none;
  }
}
.disable {
  pointer-events: none;
}
</style>
